import React from 'react';
import AppLayout from '../../layouts/app/AppLayout';


const Dashboard = () => {
    // custom Dashboard functions here
    const pageName = "Dashboard";
    return (
        <AppLayout
            pageName={pageName}
        >

            <div className="main-content-wrapper f-wrp">
            <div className="container">
                    <div className='panel-main-header f-wrp'>
                        <h1>Dashboard</h1>
                        <p>This page will be used as a dashboard.</p>
                    </div>
                    <div className='panel-tabel-sec f-wrp'>
                        <h1 style={{textAlign: 'center'}}>Dashboard contents comes here</h1>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default Dashboard;