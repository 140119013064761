/* eslint-disable global-require */
const AppIcons = {
  sampleIcon: require('./sample.ico'),
  logo: require('../Logo.png'),
  loginBG: require('../login.jpg'),
  rigisterBG: require('../register.jpg'),

};
export default AppIcons;
/* eslint-enable global-require */
