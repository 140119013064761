import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { PropTypes } from 'prop-types';

const ConfirmDialog = (props) => {
    const {
        isOpen = false,
        title = "",
        description = "",
        className = "",
        cancelButtonText = 'Cancel',
        onClose,
        okayButtonText = "Confirm",
        onConfirm } = props;

    const [open, setOpen] = React.useState(isOpen);

    /**
     * Handles the close click
     */
    const handleClose = () => {
        setOpen(false);
        if (typeof onClose === "function") {
            onClose();
        }
    };

    return (
        <div>
            <Dialog
                className={className}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{cancelButtonText}</Button>
                    <Button onClick={onConfirm} autoFocus>
                        {okayButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ConfirmDialog.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    cancelButtonText: PropTypes.string,
    okayButtonText: PropTypes.string,
    className: PropTypes.string,
}

export default ConfirmDialog;