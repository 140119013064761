import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AppIcons from '../../../assets/images/icons/index';
import SVGIcons from '../../../assets/images/icons/svgIcons';


import './DrawerApp.scss'


const drawerWidth = 240;
const navItems = [
  {
    title: 'Users',
    icon: SVGIcons.ProfileIcon(),
    link: '/users'
  },
  {
    title: 'Parents',
    icon: SVGIcons.ProfileIcon(),
    link: '/parents'
  },
  {
    title: 'Contact us queries',
    icon: SVGIcons.ProfileIcon(),
    link: '/contact-us-queries'
  }
];

const mobNavItems = [
  {
    title: 'Home',
    link: '/'
  },
  {
    title: 'Contact',
    icon: SVGIcons.Pencil(),
    link: '/contact-us',
  },
  {
    title: 'Signup',
    icon: SVGIcons.Pencil(),
    link: '/auth/register'
  },
];

function DrawerAppBar(props) {
  const { window } = props;
  const { pathname } = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} className='mob-drawer'>

      <div className="close-btn f-wrp">
        <span>{SVGIcons.Pencil()}</span>
      </div>

      {/* <Divider /> */}
      <List>
        {mobNavItems.map((element) => (
          <ListItem key={element.title} disablePadding>
            <Link to={`${element.link}`} style={{ width: '100%' }} >
              <ListItemButton sx={{ textAlign: 'center' }}>
                <span className='menu-icon'>{element.icon}</span>
                <ListItemText primary={element.title} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" className="custom-navbar" >
        <Toolbar className="custom-header" style={{ justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
          <IconButton
            className='pc-icon'
            color="inherit"
            aria-label="open drawer"
            edge="start"
            // onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <div className="logo-wrp f-wrp">
              <span><Link to="/" style={{ display: 'inline-block' }}><img src={AppIcons.logo} alt="Logo" /></Link></span>
            </div>
          </IconButton>
          <IconButton
            className='mob-icon'
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <div className="logo-wrp f-wrp">
              <span>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.33337 9.33337H9.33337M26.6667 9.33337H14.6667M26.6667 22.6667H22.6667M5.33337 22.6667H17.3334M5.33337 16H26.6667" stroke="#1D1D1B" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </span>
            </div>
          </IconButton>

          <Box className="logo-sec"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <div className="logo-wrp f-wrp">
              <span><Link to="/" style={{ display: 'inline-block' }}><img src={AppIcons.logo} alt="Logo" /></Link></span>
            </div>
          </Box>
          <Box className="menu-wrp" sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((element, key) => (
              <Link className={pathname.includes(element.link) ? 'active-nav' : ''} key={key} to={`${element.link}`} style={{ width: '100%' }} >
                <Button className="nav-link-btn" key={element.link} sx={{ color: '#fff' }}>
                  <span className='menu-icon'>{element.icon}</span>
                  <ListItemText primary={element.title} />
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          className='mob-draw-wrp'
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;