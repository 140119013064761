import React from 'react';
import Box from '@mui/material/Box';

import { Stack, Grid, InputAdornment, TextField, Button } from '@mui/material';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';

import AppLayout from '../../layouts/app/AppLayout';
import SVGIcons from '../../assets/images/icons/svgIcons';


const SampleForm = () => {

    const [open, setOpen] = React.useState(false);
    const [openCTForm, setOpenCTForm] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    const handleClickCTForms = () => {
        setOpenCTForm(!openCTForm);
    };
    const [checked, setChecked] = React.useState([0]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    const pageName = "Forms";
    return (
        <AppLayout
            pageName={pageName}
        >
            <div className="main-content-wrapper f-wrp">
                <div className="container">
                    <div className='panel-main-header f-wrp'>
                        <h1>Forms</h1>
                        <p>The following forms are required for enrollment.</p>
                    </div>
                    <div className='panel-form-sec f-wrp'>
                        <div className='form-category-sec f-wrp'>
                            <Box className='form-select-list' spacing={3}>
                                <List
                                    sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader" className="selectList general"
                                >
                                    <ListItemButton onClick={handleClick}>
                                        <ListItemText primary="General forms " />
                                        {open ? <span className="reverse">{SVGIcons.ChevronUpIcon()}</span> : <span>{SVGIcons.ChevronDownIcon()}</span>}
                                    </ListItemButton>
                                    <Collapse in={open} timeout="auto" unmountOnExit>
                                        <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }} className="formlist">
                                            {['Enrollment',
                                                'Immunization',
                                                'Emergency',
                                                'Permission',
                                                'Communication',
                                                'Special needs (optional)',
                                            ].map((value) => {
                                                const labelId = `${value}`;

                                                return (
                                                    <ListItem
                                                        key={value}
                                                        secondaryAction={<IconButton edge="end" aria-label="comments" />}
                                                        disablePadding
                                                    >
                                                        <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                                            <ListItemText id={labelId} primary={`${value}`} />
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={checked.indexOf(value) !== -1}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                            </ListItemIcon>
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Collapse>
                                </List>
                            </Box>
                            <Box className='form-select-list' spacing={3}>
                                <List
                                    sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader" className="selectList CTforn"
                                >
                                    <ListItemButton onClick={handleClickCTForms}>
                                        <ListItemText primary="CT forms " />
                                        {openCTForm ? <span className="reverse">{SVGIcons.ChevronUpIcon()}</span> : <span>{SVGIcons.ChevronDownIcon()}</span>}
                                    </ListItemButton>
                                    <Collapse in={openCTForm} timeout="auto" unmountOnExit>
                                        <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }} className="formlist">
                                            {['C4K',
                                                'EC Early Childhood',
                                                'Redet',
                                                'Self employment',
                                            ].map((value) => {
                                                const labelId = `${value}`;

                                                return (
                                                    <ListItem
                                                        key={value}
                                                        secondaryAction={<IconButton edge="end" aria-label="comments" />}
                                                        disablePadding
                                                    >
                                                        <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                                            <ListItemText id={labelId} primary={`${value}`} />
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={checked.indexOf(value) !== -1}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                            </ListItemIcon>
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Collapse>
                                </List>
                            </Box>
                        </div>
                        <div className='form-detail-sec f-wrp'>
                            <div className='general-info-sec f-wrp'>
                                <div className='info-head f-wrp'>
                                    <span>Form title</span>
                                </div>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Stack className='panel-form' spacing={3}>
                                            <TextField type='text'
                                                defaultValue="Title"
                                                name="title"
                                                label="Title:"
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Stack className='panel-form' spacing={3}>
                                            <TextField
                                                id="Subtitle"
                                                label="Subtitle:"
                                                name='subTitle'
                                                defaultValue="Title"
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Stack className='panel-form' spacing={3}>
                                            <TextField type='number'
                                                id="price"
                                                label="price"
                                                name='price'
                                                defaultValue="9000"

                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            AED
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Stack className='panel-form' spacing={3}>
                                            <TextField type='text'
                                                defaultValue="Title"
                                                name="title"
                                                label="Title:"
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Stack className='panel-form' spacing={3}>
                                            <TextField type='text'
                                                defaultValue="Title"
                                                name="title"
                                                label="Title:"
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className='button-sec f-wrp'>
                                <div className='lft-btn-sec'>
                                    <Button onClick={() => navigator('/')} className='cancel-btn'>Cancel</Button>
                                </div>
                                <div className='ryt-btn-sec'>
                                    <Button onClick={() => navigator('/')} className='cancel-btn'>Upload</Button>
                                    <Button onClick={() => navigator('/')} className='pln-btn'>Download</Button>
                                    <Button onClick={() => navigator('/')} className='fill-btn'>Save</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default SampleForm;