import React from 'react';
import './header.scss';
// import Nav from '../nav-section/Nav';

import DrawerAppBar from '../nav-section/BurgerMenu/DrawerAppBar';

export default function AppHeader() {
    return (
        <div className='main-header'>
            <DrawerAppBar />
        </div>
    )

}