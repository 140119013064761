/**
@author Geethananth M
Use this file to write functions that can be used across the app
* */

import uniqid from 'uniqid';

/**
 * Returns a unique random string
 * @param {string} prefix 
 * @param {string} suffix 
 * @returns string the generated string
 */
export const getUniqueId = (prefix = '', suffix = '') => uniqid(prefix, suffix);


/**
 * Returns a currency formatted string
 * @param {string | number} input 
 * @param {boolean} includeUSDSign
 * @returns string the generated string
 */
export const usdFormatter = (input, includeUSDSign = false) => {
    const numberAs = Math.floor(input);
    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,

        // These options are needed to round to whole numbers if that's what you want.
        // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    const replacer = includeUSDSign ? '$' : '';
    return formatter.format(numberAs).replace("$", replacer);
}

