import React, { useEffect, useState } from 'react';
import AppLayout from '../../layouts/app/AppLayout';
import AppTable from '../../components/table/index';
import ApiCaller from '../../services/api/general';
import { showSnackbar } from '../../components/notification/index';
import AppPopOverMenu from '../../components/appPopOverMenu/index';
import ConfirmDialog from '../../components/dialogs/confirm.dialog';



const ContactUsQueries = () => {
    const pageName = "Contact us Queries";
    const apiCaller = new ApiCaller('user-query')
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => setDataArr(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * Query delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.updateData(selectedId, { isDeleted: true })
                .then(() => showSnackbar().success('Query deleted successfully'))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 160,
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            width: 250,
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone',
            flex: 1,
            width: 250,
        },
        {
            field: 'query',
            headerName: 'Message',
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            width: 90,
            sortable: false            
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 1,
            width: 90,
            sortable: false,
            renderCell: (params) => {
                const {id} = params.row;
                return <AppPopOverMenu options={[
                    {
                        label: 'View',
                        onClick: () =>null
                    },
                    {
                        label: "Delete",
                        onClick: () => {
                            setSelectedId(id);
                            setOpenDeleteDialog(true);
                        }
                    }
                ]} />
            }
        },

    ]


    return (
        <AppLayout
            pageName={pageName}
        >

            <div className="main-content-wrapper f-wrp">
                <div className="container">
                    <div className='panel-main-header f-wrp'>
                        <h1>Contact us queries</h1>
                    </div>
                    <div className='panel-tabel-sec f-wrp'>
                        <AppTable
                            columns={tableColumns}
                            isLoading={isLoading}
                            data={dataArr}
                        />
                    </div>
                    {openDeleteDialog && <ConfirmDialog
                        isOpen={openDeleteDialog}
                        onClose={() => {
                            setOpenDeleteDialog(false);
                            setSelectedId('');
                        }}
                        title={`Delete query?`}
                        className={'delete-popup'}
                        description={`Are you sure you want to delete this query?\nThis action is permanent and cannot be undone.`}
                        okayButtonText={'Delete query'}
                        onConfirm={deleteData}
                    />}
                </div>
            </div>
        </AppLayout>
    )
}

export default ContactUsQueries;