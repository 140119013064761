import instance from './axios';

const axiosInstance = instance;


/**
* Gets the data by id
* @param {string} parentId;
* @param {string} childId;
* @param {string} formName;
* @returns Object
*/
export const generatePDFBy = async (parentId, childId, formName) => {
    try {
        const result = await axiosInstance.get(`/pdf-generate?userId=${parentId}&childId=${childId}&formName=${formName}`, {responseType: 'blob'});        
        // create file link in browser's memory
        const href = URL.createObjectURL(result.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${formName}.pdf`); // or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return true;
    } catch (e) {
        return null;
    }
}