import React from 'react';
// eslint-disable-next-line
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
// eslint-disable-next-line
import { CircularProgress, CircularProgressProps } from '@mui/material';

/**
 * The Eye closed svg icon
 * @param {SvgIconProps} props 
 * @returns HTMLElement
 */
const _eyeClosed = (props) => (
    <SvgIcon {...props}>
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.21 13.1524L23.6252 17.2336" stroke="#AEACAC" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.2632 15.4255L17.012 19.5893" stroke="#AEACAC" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.7264 15.4158L9.97754 19.5899" stroke="#AEACAC" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.77949 13.1524L3.36426 17.2542" stroke="#AEACAC" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.375 10.8382C5.14687 12.9873 8.39531 15.7046 13.5 15.7046C18.6047 15.7046 21.8531 12.9873 23.625 10.8382" stroke="#AEACAC" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);

/**
 * The Eye open svg icon
 * @param {SvgIconProps} props 
 * @returns HTMLElement
 */
const _eyeOpen = (props) => (
    <SvgIcon {...props}>
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 5.90625C5.0625 5.90625 1.6875 13.5 1.6875 13.5C1.6875 13.5 5.0625 21.0938 13.5 21.0938C21.9375 21.0938 25.3125 13.5 25.3125 13.5C25.3125 13.5 21.9375 5.90625 13.5 5.90625Z" stroke="#AEACAC" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.5 17.7188C15.83 17.7188 17.7188 15.83 17.7188 13.5C17.7188 11.17 15.83 9.28125 13.5 9.28125C11.17 9.28125 9.28125 11.17 9.28125 13.5C9.28125 15.83 11.17 17.7188 13.5 17.7188Z" stroke="#AEACAC" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);

/**
 * The Eye open svg icon
 * @param {SvgIconProps} props 
 * @returns HTMLElement
 */
const _pencilIcon = (props) => (
    <SvgIcon {...props}>
        <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.32459 9.73339H2.16175C2.06614 9.73339 1.97445 9.69541 1.90685 9.62781C1.83925 9.56021 1.80127 9.46852 1.80127 9.37292V7.35877C1.80111 7.31196 1.81018 7.26558 1.82796 7.22228C1.84574 7.17899 1.87189 7.13962 1.90491 7.10644L7.31201 1.69934C7.34555 1.66528 7.38554 1.63823 7.42963 1.61977C7.47372 1.6013 7.52105 1.5918 7.56885 1.5918C7.61665 1.5918 7.66398 1.6013 7.70807 1.61977C7.75216 1.63823 7.79215 1.66528 7.82569 1.69934L9.83533 3.70898C9.86939 3.74252 9.89643 3.7825 9.9149 3.82659C9.93336 3.87069 9.94287 3.91801 9.94287 3.96581C9.94287 4.01362 9.93336 4.06094 9.9149 4.10504C9.89643 4.14913 9.86939 4.18911 9.83533 4.22265L4.32459 9.73339Z" stroke="#2F4C30" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.73181 9.73242H4.32471" stroke="#2F4C30" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.12866 2.88281L8.65198 5.40613" stroke="#2F4C30" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);

/**
* The Google
* @param {SvgIconProps} props 
* @returns HTMLElement
*/
const _googleIcon = (props) => (
    <SvgIcon viewBox='0 0 33 32' {...props}>
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.574 13.3885H28.5V13.3332H16.5V18.6665H24.0354C22.936 21.7712 19.982 23.9998 16.5 23.9998C12.082 23.9998 8.50002 20.4178 8.50002 15.9998C8.50002 11.5818 12.082 7.99984 16.5 7.99984C18.5394 7.99984 20.3947 8.76917 21.8074 10.0258L25.5787 6.2545C23.1974 4.03517 20.012 2.6665 16.5 2.6665C9.13669 2.6665 3.16669 8.6365 3.16669 15.9998C3.16669 23.3632 9.13669 29.3332 16.5 29.3332C23.8634 29.3332 29.8334 23.3632 29.8334 15.9998C29.8334 15.1058 29.7414 14.2332 29.574 13.3885Z" fill="#FFC107" />
            <path d="M4.70398 9.79384L9.08465 13.0065C10.27 10.0718 13.1406 7.99984 16.5 7.99984C18.5393 7.99984 20.3946 8.76917 21.8073 10.0258L25.5786 6.2545C23.1973 4.03517 20.012 2.6665 16.5 2.6665C11.3786 2.6665 6.93731 5.55784 4.70398 9.79384Z" fill="#FF3D00" />
            <path d="M16.5 29.3333C19.944 29.3333 23.0733 28.0153 25.4393 25.872L21.3127 22.38C19.929 23.4322 18.2383 24.0013 16.5 24C13.032 24 10.0873 21.7886 8.978 18.7026L4.63 22.0526C6.83667 26.3706 11.318 29.3333 16.5 29.3333Z" fill="#4CAF50" />
            <path d="M29.574 13.3888H28.5V13.3335H16.5V18.6668H24.0353C23.5095 20.1444 22.5622 21.4356 21.3107 22.3808L21.3127 22.3795L25.4393 25.8715C25.1473 26.1368 29.8333 22.6668 29.8333 16.0002C29.8333 15.1062 29.7413 14.2335 29.574 13.3888Z" fill="#1976D2" />
        </svg>


    </SvgIcon>
);

/**
* The CreditCard
* @param {SvgIconProps} props 
* @returns HTMLElement
*/
const _creditCardIcon = (props) => (
    <SvgIcon viewBox='0 0 40 40' {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path d="M0 10C0 8.67392 0.526784 7.40215 1.46447 6.46447C2.40215 5.52678 3.67392 5 5 5H35C36.3261 5 37.5979 5.52678 38.5355 6.46447C39.4732 7.40215 40 8.67392 40 10V30C40 31.3261 39.4732 32.5979 38.5355 33.5355C37.5979 34.4732 36.3261 35 35 35H5C3.67392 35 2.40215 34.4732 1.46447 33.5355C0.526784 32.5979 0 31.3261 0 30V10ZM5 7.5C4.33696 7.5 3.70107 7.76339 3.23223 8.23223C2.76339 8.70107 2.5 9.33696 2.5 10V12.5H37.5V10C37.5 9.33696 37.2366 8.70107 36.7678 8.23223C36.2989 7.76339 35.663 7.5 35 7.5H5ZM37.5 17.5H2.5V30C2.5 30.663 2.76339 31.2989 3.23223 31.7678C3.70107 32.2366 4.33696 32.5 5 32.5H35C35.663 32.5 36.2989 32.2366 36.7678 31.7678C37.2366 31.2989 37.5 30.663 37.5 30V17.5Z" fill="#FFF4EE" />
            <path d="M5 25C5 24.337 5.26339 23.7011 5.73223 23.2322C6.20107 22.7634 6.83696 22.5 7.5 22.5H10C10.663 22.5 11.2989 22.7634 11.7678 23.2322C12.2366 23.7011 12.5 24.337 12.5 25V27.5C12.5 28.163 12.2366 28.7989 11.7678 29.2678C11.2989 29.7366 10.663 30 10 30H7.5C6.83696 30 6.20107 29.7366 5.73223 29.2678C5.26339 28.7989 5 28.163 5 27.5V25Z" fill="#FFF4EE" />
        </svg>
    </SvgIcon>
);

/**
* The Forms
* @param {SvgIconProps} props 
* @returns HTMLElement
*/
const _formsIcon = (props) => (
    <SvgIcon viewBox='0 0 40 40' {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <g id="clarity:form-line" clipPath="url(#clip0_156_979)">
                <path id="Vector" d="M23.3337 13.3332H7.7781C7.48342 13.3332 7.2008 13.2161 6.99243 13.0077C6.78406 12.7994 6.66699 12.5167 6.66699 12.2221V7.77762C6.66699 7.48293 6.78406 7.20031 6.99243 6.99194C7.2008 6.78357 7.48342 6.6665 7.7781 6.6665H23.3337C23.6283 6.6665 23.911 6.78357 24.1193 6.99194C24.3277 7.20031 24.4448 7.48293 24.4448 7.77762V12.2221C24.4448 12.5167 24.3277 12.7994 24.1193 13.0077C23.911 13.2161 23.6283 13.3332 23.3337 13.3332ZM8.88921 11.1109H22.2225V8.82206H8.88921V11.1109Z" fill="white" />
                <path id="Vector_2" d="M23.3337 15.6445H7.7781C7.48342 15.6445 7.2008 15.7616 6.99243 15.97C6.78406 16.1783 6.66699 16.461 6.66699 16.7556V21.1112C6.66699 21.4059 6.78406 21.6885 6.99243 21.8969C7.2008 22.1052 7.48342 22.2223 7.7781 22.2223H20.4003L24.4448 18.1112V16.7556C24.4448 16.461 24.3277 16.1783 24.1193 15.97C23.911 15.7616 23.6283 15.6445 23.3337 15.6445ZM22.2225 20.0001H8.88921V17.7779H22.2225V20.0001Z" fill="white" />
                <path id="Vector_3" d="M12.2893 35.0111V34.9444L12.6449 33.3999H4.44488V4.44439H26.6671V15.8333L28.8893 13.7333V3.33328C28.8893 3.03859 28.7723 2.75598 28.5639 2.5476C28.3555 2.33923 28.0729 2.22217 27.7782 2.22217H3.33377C3.03908 2.22217 2.75647 2.33923 2.54809 2.5476C2.33972 2.75598 2.22266 3.03859 2.22266 3.33328V34.4444C2.22266 34.7391 2.33972 35.0217 2.54809 35.2301C2.75647 35.4384 3.03908 35.5555 3.33377 35.5555H12.2227C12.2318 35.3727 12.2541 35.1907 12.2893 35.0111Z" fill="white" />
                <path id="Vector_4" d="M24.4448 21.2998L23.5781 22.1776C23.7931 22.1333 23.9902 22.0262 24.1445 21.87C24.2987 21.7138 24.4032 21.5154 24.4448 21.2998Z" fill="white" />
                <path id="Vector_5" d="M6.66699 29.9332C6.66699 30.2279 6.78406 30.5105 6.99243 30.7189C7.2008 30.9273 7.48342 31.0443 7.7781 31.0443H13.1559L13.4892 29.5999L13.6337 28.9888V28.9332H8.88921V26.6666H15.9337L18.1559 24.4443H7.7781C7.48342 24.4443 7.2008 24.5614 6.99243 24.7698C6.78406 24.9781 6.66699 25.2608 6.66699 25.5554V29.9332Z" fill="white" />
                <path id="Vector_6" d="M37.2111 18.5222L33.4667 14.7778C33.3005 14.6111 33.1031 14.4789 32.8857 14.3887C32.6684 14.2985 32.4353 14.2521 32.2 14.2521C31.9647 14.2521 31.7316 14.2985 31.5143 14.3887C31.2969 14.4789 31.0995 14.6111 30.9333 14.7778L15.7 30.1L14.4444 35.4444C14.3974 35.675 14.3964 35.9126 14.4413 36.1436C14.4863 36.3745 14.5764 36.5944 14.7064 36.7905C14.8365 36.9866 15.0039 37.1551 15.1992 37.2864C15.3945 37.4177 15.6138 37.5091 15.8444 37.5556C15.959 37.5671 16.0744 37.5671 16.1889 37.5556C16.325 37.5777 16.4639 37.5777 16.6 37.5556L21.9889 36.3667L37.2111 21.1111C37.3774 20.9459 37.5094 20.7494 37.5994 20.533C37.6895 20.3165 37.7359 20.0844 37.7359 19.85C37.7359 19.6156 37.6895 19.3835 37.5994 19.167C37.5094 18.9506 37.3774 18.7541 37.2111 18.5889V18.5222ZM20.8556 34.3444L16.7889 35.2444L17.7778 31.2111L29.2 19.6667L32.3333 22.8L20.8556 34.3444ZM33.5889 21.5444L30.4556 18.4111L32.2222 16.6667L35.3778 19.8222L33.5889 21.5444Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_156_979">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);

/**
* The profile
* @param {SvgIconProps} props 
* @returns HTMLElement
*/
const _profileIcon = (props) => (
    <SvgIcon viewBox='0 0 40 40' {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <g id="ph:user-fill">
                <g clipPath="url(#clip0_156_989)">
                    <rect width="40" height="40" rx="20" fill="white" fillOpacity="0.3" />
                    <path id="Vector" d="M36.0829 39.3793C35.9732 39.5693 35.8154 39.7271 35.6253 39.8369C35.4352 39.9466 35.2196 40.0043 35.0001 40.0043H5.00012C4.78081 40.004 4.56542 39.9461 4.37557 39.8363C4.18573 39.7265 4.02811 39.5687 3.91855 39.3787C3.80899 39.1887 3.75135 38.9732 3.7514 38.7539C3.75146 38.5346 3.80922 38.3192 3.91887 38.1293C6.29856 34.0152 9.96575 31.0652 14.2454 29.6667C12.1285 28.4065 10.4838 26.4862 9.56385 24.2008C8.64392 21.9154 8.49963 19.3911 9.15315 17.0157C9.80668 14.6404 11.2219 12.5452 13.1814 11.0519C15.1409 9.55871 17.5365 8.75 20.0001 8.75C22.4638 8.75 24.8593 9.55871 26.8188 11.0519C28.7784 12.5452 30.1936 14.6404 30.8471 17.0157C31.5006 19.3911 31.3563 21.9154 30.4364 24.2008C29.5165 26.4862 27.8717 28.4065 25.7548 29.6667C30.0345 31.0652 33.7017 34.0152 36.0814 38.1293C36.1913 38.3191 36.2493 38.5346 36.2496 38.754C36.2499 38.9735 36.1924 39.1891 36.0829 39.3793Z" fill="#FFF4EE" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_156_989">
                    <rect width="40" height="40" rx="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);



/**
* The chevronUp
* @param {SvgIconProps} props 
* @returns HTMLElement
*/
const _chevronUpIcon = (props) => (
    <SvgIcon viewBox='0 0 25 24' {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <g id="tabler:chevron-up">
                <path id="Vector" d="M6.75064 15L12.8338 9L18.9169 15" stroke="#5D5848" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    </SvgIcon>
);


/**
* The chevronDown
* @param {SvgIconProps} props 
* @returns HTMLElement
*/
const _chevronDownIcon = (props) => (
    <SvgIcon viewBox='0 0 25 24' {...props}>
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 12L16 20L24 12" stroke="#5D5848" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);

/**
* The status inactive icon
* @param {SvgIconProps} props 
* @returns HTMLElement
*/
const _statusInactiveIcon = (props) => (
    <SvgIcon viewBox='0 0 25 24' {...props}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_948_9687)">
                <mask id="mask0_948_9687" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <path d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z" fill="white" stroke="white" strokeWidth="2.66667" strokeLinejoin="round" />
                    <path d="M9.5 9V15M14.5 9V15" stroke="black" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round" />
                </mask>
                <g mask="url(#mask0_948_9687)">
                    <path d="M0 0H24V24H0V0Z" fill="#FFBA0A" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_948_9687">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);


/**
* The status active icon
* @param {SvgIconProps} props 
* @returns HTMLElement
*/
const _statusActiveIcon = (props) => (
    <SvgIcon viewBox='0 0 25 24' {...props}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_948_9667)">
                <mask id="mask0_948_9667" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <path d="M12 22C13.3135 22.0017 14.6143 21.7438 15.8278 21.2412C17.0413 20.7385 18.1435 20.001 19.071 19.071C20.001 18.1435 20.7385 17.0413 21.2412 15.8278C21.7438 14.6143 22.0017 13.3135 22 12C22.0017 10.6865 21.7438 9.3857 21.2411 8.17222C20.7385 6.95875 20.001 5.85656 19.071 4.92901C18.1435 3.99902 17.0413 3.26151 15.8278 2.75885C14.6143 2.25619 13.3135 1.99831 12 2.00001C10.6865 1.99833 9.3857 2.25623 8.17222 2.75889C6.95875 3.26154 5.85656 3.99904 4.92901 4.92901C3.99904 5.85656 3.26154 6.95875 2.75889 8.17222C2.25623 9.3857 1.99833 10.6865 2.00001 12C1.99831 13.3135 2.25619 14.6143 2.75885 15.8278C3.26151 17.0413 3.99902 18.1435 4.92901 19.071C5.85656 20.001 6.95875 20.7385 8.17222 21.2411C9.3857 21.7438 10.6865 22.0017 12 22Z" fill="white" stroke="white" strokeWidth="2.66667" strokeLinejoin="round" />
                    <path d="M8 12L11 15L17 9" stroke="black" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round" />
                </mask>
                <g mask="url(#mask0_948_9667)">
                    <path d="M0 0H24V24H0V0Z" fill="#1FAF38" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_948_9667">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);


/**
 * The CircularProgress icon
 * @param {CircularProgressProps} props 
 * @returns HTMLElement
 */
const _circularProgress = (props) => <CircularProgress {...props} />


const SVGIcons = {
    EyeClosed: _eyeClosed,
    EyeOpen: _eyeOpen,
    CircularProgress: _circularProgress,
    Pencil: _pencilIcon,
    GoogleIcon: _googleIcon,
    CreditCardIcon: _creditCardIcon,
    FormsIcon: _formsIcon,
    ProfileIcon: _profileIcon,
    ChevronDownIcon: _chevronDownIcon,
    ChevronUpIcon: _chevronUpIcon,
    StatusInactiveIcon: _statusInactiveIcon,
    StatusActiveIcon: _statusActiveIcon,

}

export default SVGIcons;