import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppLayout from '../../layouts/app/AppLayout';
import AppTable from '../../components/table/index';
import ApiCaller from '../../services/api/general';
import { showSnackbar } from '../../components/notification/index';
import AppPopOverMenu from '../../components/appPopOverMenu/index';
import ConfirmDialog from '../../components/dialogs/confirm.dialog';



const ParentsView = () => {
    const pageName = "Parents Details";
    const navigator = useNavigate();
    const params = useParams();
    const userId = params.id || '';
    const apiCaller = new ApiCaller('users')
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getData()
        return () => {
            setData({})
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData(userId)
            .then((data) => setData(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

   

    /**
   * user delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.updateData(selectedId, { isDeleted: true })
                .then(() => showSnackbar().success('User deleted successfully'))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getData();
                })
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 160,
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            width: 250,
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 1,
            width: 90,
            sortable: false,
            renderCell: (params) => {
                const { id } = params.row;
                return <AppPopOverMenu options={[
                    {
                        label: 'View',
                        onClick: () => navigator(`/parents/child/${id}`)
                    }
                ]} />
            }
        },

    ]


    return (
        <AppLayout
            pageName={pageName}
        >

            <div className="main-content-wrapper f-wrp">
                {(!isLoading && data?.id) ? <div className="container">
                    <div className='panel-main-header f-wrp'>
                        <h1>{`${data?.firstName} ${data?.lastName}`}</h1>
                    </div>
                    <div className="user-details-section">
                        <span className='label-header'>Email</span> : <span>{data?.email}</span>
                    </div>
                    <h2>Children</h2>
                    <div className='panel-tabel-sec f-wrp'>
                        <AppTable
                            columns={tableColumns}
                            isLoading={isLoading}
                            data={data?.children || []}
                        />
                    </div>
                    {openDeleteDialog && <ConfirmDialog
                        isOpen={openDeleteDialog}
                        onClose={() => {
                            setOpenDeleteDialog(false);
                            setSelectedId('');
                        }}
                        title={`Delete user`}
                        className={'delete-popup'}
                        description={`Are you sure you want to delete this user?\nThis action is permanent and cannot be undone.`}
                        okayButtonText={'Delete user'}
                        onConfirm={deleteData}
                    />}
                </div> : (isLoading) ? <span>Loading...</span> : <span>Nothing found</span>}
            </div>
        </AppLayout>
    )
}

export default ParentsView;