import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppLayout from '../../layouts/app/AppLayout';
import AppTable from '../../components/table/index';
import ApiCaller from '../../services/api/general';
import { showSnackbar } from '../../components/notification/index';
import SVGIcons from '../../assets/images/icons/svgIcons';
import AppPopOverMenu from '../../components/appPopOverMenu/index';
import ConfirmDialog from '../../components/dialogs/confirm.dialog';



const ParentsList = () => {
    const pageName = "Parents";
    const navigator = useNavigate();
    const apiCaller = new ApiCaller('users')
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => setDataArr(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * status update API
   * @param {string} id 
   * @param {string} status 
   */
    const toggleActiveStatus = (id, status) => {
        status = status === "active" ? "inactive" : "active";
        const statusMsg = status === "active" ? "resumed" : "paused";
        if (id) {
            apiCaller.updateData(id, { status })
                .then(() => showSnackbar().success(`The selected user has been ${statusMsg} successfully.`))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    getDataArr();
                })
        }

    }

    /**
   * User delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.updateData(selectedId, { isDeleted: true })
                .then(() => showSnackbar().success('User deleted successfully'))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'fullName',
            headerName: 'Name',
            width: 160,
            flex: 1,
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            width: 250,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            width: 90,
            sortable: false,
            renderCell: (params) => {
                const { status } = params.row;
                return <>{(status === 'active' && <span className='tab-status'>{SVGIcons.StatusActiveIcon()}  </span>) ||
                    (status === 'inactive' && <span className='tab-status'>{SVGIcons.StatusInactiveIcon()} </span>)}</>
            }
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 1,
            width: 90,
            sortable: false,
            renderCell: (params) => {
                const { id } = params.row;
                const { status } = params.row;
                return <AppPopOverMenu options={[
                    {
                        label: 'View',
                        onClick: () => navigator(`view/${id}`)
                    },
                    {
                        label: status === "active" ? "Pause" : "Resume",
                        onClick: () => {
                            toggleActiveStatus(id, status)
                        }
                    },
                    {
                        label: "Delete",
                        onClick: () => {
                            setSelectedId(id);
                            setOpenDeleteDialog(true);
                        }
                    }
                ]} />
            }
        },

    ]


    return (
        <AppLayout
            pageName={pageName}
        >

            <div className="main-content-wrapper f-wrp">
                <div className="container">
                    <div className='panel-main-header f-wrp'>
                        <h1>Users</h1>
                    </div>
                    <div className='panel-tabel-sec f-wrp'>
                        <AppTable
                            columns={tableColumns}
                            isLoading={isLoading}
                            data={dataArr}
                        />
                    </div>
                    {openDeleteDialog && <ConfirmDialog
                        isOpen={openDeleteDialog}
                        onClose={() => {
                            setOpenDeleteDialog(false);
                            setSelectedId('');
                        }}
                        title={`Delete user`}
                        className={'delete-popup'}
                        description={`Are you sure you want to delete this user?\nThis action is permanent and cannot be undone.`}
                        okayButtonText={'Delete user'}
                        onConfirm={deleteData}
                    />}
                </div>
            </div>
        </AppLayout>
    )
}

export default ParentsList;