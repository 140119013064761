import config from "../config/config";



/**
 * Returns the token key
 * @returns string || ''
 */
const getTokenKey = ()=> {
    const {tokenKey} = config;
    return tokenKey;
}

/**
 * returns true if logged in
 * @returns Bool {is logged in}
 */
export const isLoggedIn = ()=> window.localStorage.getItem(getTokenKey())!== null;

/**
 * returns the token if logged in
 * @returns {string} the token
 */
export const getAuthToken = ()=> window.localStorage.getItem(getTokenKey());

/**
 * logs the user out
 * @returns void
 */
export const logoutUser = ()=> {
    window.localStorage.removeItem(getTokenKey());
    window.location.href = "/"
};