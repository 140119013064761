import { Navigate, useRoutes } from 'react-router-dom';

// pages
import Dashboard from '../pages/dashboard';
import LoginPage from '../pages/auth/login';
import RegisterPage from '../pages/auth/register';
import ForgotPasswordPage from '../pages/auth/forgotPassword';
import PasswordResetMailSuccess from '../pages/auth/passwordResetMailSuccess';
import Page404 from '../pages/pageNotFound';
import SampleForm from '../pages/clientForms/sampleForm';
import { isLoggedIn } from '../helpers/auth.helper';
import UserList from '../pages/users/list';
import ParentsList from '../pages/parents/list';
import ParentsView from '../pages/parents/view';
import ChildViewPage from '../pages/parents/childView';
import ContactUsQueries from '../pages/contactUs';

// ----------------------------------------------------------------------

export default function Router() {
  const isUserLoggedIn = isLoggedIn();
  const routes = [
    {
      path: '/',
      element: isUserLoggedIn ?  <Dashboard /> : <LoginPage/>,
    },
    {
      path: '/forms',
      element: <SampleForm />,
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: 'auth',
      children: [
        { element: <Navigate to="login" />, index: true },
        { path:'register', element: <RegisterPage /> },
        { path: 'login', element: <LoginPage /> },
        {path: 'forgot-password', element: <ForgotPasswordPage />},
        {path: 'reset-password-email-sent', element: <PasswordResetMailSuccess />}
        // {path: 'reset-password', element: <ResetPassword />}
      ]
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const privateRoutes = isUserLoggedIn ? [
    {
      path: '/users',
      children: [
        { element: <UserList />, index: true },
        { path: 'list', element: <Dashboard /> },
      ],
    },
    {
      path: '/contact-us-queries',
      children: [
        { element: <ContactUsQueries />, index: true }
      ],
    },
    {
      path: 'parents',
      children: [
        { element: <ParentsList />, index: true },
        { path: 'view/:id', element: <ParentsView /> },
        { path: 'child/:id', element: <ChildViewPage /> },
      ],
    },
  ] : [];

  return useRoutes(routes.concat(privateRoutes));
}
