import instance from './axios';

class ApiCaller {
    
    axiosInstance = instance;

    constructor(route) {
        this.route = route;
    }
    
    /**
    * Post a property
    * @param {Object} postData email and password
    */
    postData = async (postData) => {
        try {
            const result = await this.axiosInstance.post(`/${this.route}`, postData);
            const { data } = result;
            return data;
        } catch (e) {
            const errorMessage = e.response?.data?.message;
            return ({ success: false, message: errorMessage });
        }
    }

    /**
     * Update a property
     * @param {string} id
     */
    deleteData = async (id) => {
        try {
            const result = await this.axiosInstance.delete(`/${this.route}/${id}`);
            const { data } = result;
            return data;
        } catch (e) {
            const errorMessage = e.response?.data?.message;
            return ({ success: false, message: errorMessage });
        }
    }

    /**
     * Update a data
     * @param {string} id
     * @param {Object} postData the form data
     */
    updateData = async (id, postData) => {
        try {
            const result = await this.axiosInstance.patch(`/${this.route}/${id}`, postData);
            const { data } = result;
            return data;
        } catch (e) {
            const errorMessage = e.response?.data?.message;
            return ({ success: false, message: errorMessage });
        }
    }

    /**
     * Gets the list of data
     * @returns Object
     */
    getList = async () => {
        try {
            const result = await this.axiosInstance.get(`/${this.route}?limit=100&page=1`);
            const data = result.data?.results || [];
            return data;
        } catch (e) {
            return [];
        }
    }


    /**
     * Gets the data by id
    * @param {string} id;
     * @returns Object
     */
    getData = async (id) => {
        try {
            const result = await this.axiosInstance.get(`/${this.route}/${id}`);
            const { data = null } = result;
            return data;
        } catch (e) {
            return null;
        }
    }

}
export default ApiCaller;