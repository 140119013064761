import React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { PropTypes } from 'prop-types';

import CustomNoRowsOverlay from './noRowsOverlay';
import CustomLoadingOverlay from './loadingOverlay';
import CustomPagination from './customPagination';


// Create the data grid options object with columns and rowData properties set to our own values
const AppTable = (props)=> {
    const {isLoading = false, columns = [], data = []} = props;
    
    
    /**
     * 
     * @returns JSX Element
     */
    function CustomToolbar() {
        return (
            <GridToolbarContainer className='app-grid-tool-bar'>
                <GridToolbarExport 
                printOptions={{
                    disableToolbarButton: true
                }}
                 />
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }

    

    return (
        <Box sx={{width: '100%' }}>
            <DataGrid
                density='comfortable'
                rows={data}
                autoHeight
                loading={isLoading}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[0, 10, 15, 25]}
                checkboxSelection={false}
                disableRowSelectionOnClick
                disableColumnMenu
                pagination
                slots={{ 
                    toolbar: CustomToolbar,
                    pagination: CustomPagination,
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: CustomLoadingOverlay,
                 }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        </Box>
    );
}

AppTable.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.array,
    columns: PropTypes.array
}

export default AppTable;