import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { login } from '../services/api/auth';
import { showSnackbar } from '../components/notification';
import TextInput from '../components/inputs/textInput/textInput';

// ----------------------------------------------------------------------

export default function LoginForm() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');

    const onSubmit = () => {
        setIsLoading(true);
        login({ email, password }).then((data) => {
            if (data.success) {
                navigate('/', { replace: true });
                showSnackbar().success(data.message)
            } else {
                showSnackbar().failure(data.message)

            }
        }).finally(() => setIsLoading(false))
            ;
    };

    return (
        <>

            <Stack className='divider' direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                <Divider>Login</Divider>
            </Stack>
            <Stack className='login-form standard-form' spacing={3}>
                <TextInput
                    key={`login-email`}
                    label='Email'
                    name='email'
                    type='email'
                    required
                    placeholder='Please enter your email address'
                    className='custom-textfield'
                    getValue={(_, value) => setEmail(value)}

                />

                <TextInput
                    key={`login-password`}
                    label='Password'
                    name='password'
                    type='password'
                    required
                    placeholder='Please enter your password'
                    className='custom-textfield'
                    getValue={(_, value) => setPassword(value)}

                />

                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<></>} // To remove Mui warning
                    fullWidth
                    disabled={!email || !password}
                    size="large"
                    type="submit"
                    variant="contained"
                    className='standard-btn'
                    onClick={onSubmit}>
                    Login
                </LoadingButton>
            </Stack>
        </>
    );
}
