import config from '../../config/config';
import instance from './axios';

const axiosInstance = instance;
const {tokenKey} = config;

/**
 * login
 * @param {Object} data email and password
 */
export const login = async (data) => {
    try {
        const result = await axiosInstance.post('/auth/admin-login', data);
        const authToken = result.data?.tokens?.access?.token;
        if (authToken) {
            localStorage.setItem(tokenKey, authToken);
        }
        return ({ success: true , message: "Logged in successfully!"});
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}

/**
 * Register
 * @param {Object} data the form data with email password name
 */
export const register = async (data) => {
    try {
        const result = await axiosInstance.post('/auth/register', data);
        const authToken = result.data?.data?.token;
        if (authToken) {
            localStorage.setItem(tokenKey, authToken);
        }
        return ({ success: true , message: result.data?.message});
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}

/**
 * Forgot password
 * @param {Object} data the form data with email password name
 */
export const forgotPassword = async (data) => {
    try {
        const result = await axiosInstance.post('/auth/forgot-password', data);
        return ({ success: result.status===200 , message: result.data?.message});
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}